@import "color.scss";

html {
  scroll-behavior: smooth;
}

// Global tooltip style
.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .mat-tooltip {
      font-size: 14px;
      background-color: $color-ui;
      line-height: 18px;
    }
  }
}

.overlay__content {
  .messages {
    position: absolute !important;
    top: -20px !important;
    bottom: 0px !important;
    left: 17px !important;
    right: 0 !important;
    overflow-y: auto !important;
    height: 411px !important;
    padding-left: 15px !important;
    padding-top: 15px !important;
    background-color: #eee !important;
    padding-top: 12px !important;
    padding-right: 2px !important;

    .message {
      width: 98% !important;
      margin-left: 0 !important;
      margin-bottom: 15px !important;
      background-color: #fff !important;
      &__close {
        display: none !important;
      }
      &__icon {
        display: none !important;
      }
      &__edit {
        display: block !important;
      }
      &__delete {
        display: block !important;
      }
      &__export {
        display: block !important;
      }
      &.news {
        display: none;
      }
      &__right {
        margin-left: 0 !important;
        background-color: #fff !important;
        color: #333 !important;
      }
      &.note {
        background-color: #fff !important;
      }
    }

    .go-to-btn {
      display: none;
    }
  }
}

.has-note-wrapper {
  .messages {
    background-color: transparent !important;
    overflow: initial !important;
    left: 0 !important;
    top: 0 !important;
    padding: 0 !important;
  }
  .message {
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%) !important;
    border: 1px solid #999 !important;
    &:not(:first-child) {
      display: none !important;
    }
    &__header {
      white-space: normal !important;
    }
    &__text {
      margin-bottom: 0 !important;
      white-space: normal;
    }
    &__buttons {
      display: none !important;
    }
    &__extra-hover-text {
      display: block !important;
    }
  }
}

.notes-dialog {
  .messages {
    position: initial !important;
  }
  .message {
    &.news {
      display: none;
    }
    &__icon {
      right: 15px !important;
    }
    &__buttons {
      a:first-child {
        display: none !important;
      }
    }
  }
}

.news-dialog {
  .messages {
    position: initial !important;
    overflow: initial !important;
  }
  .message {
    width: 97% !important;

    &.note {
      display: none;
    }

    &__close {
      display: none !important;
    }

    &__reopen {
      right: 15px;
      top: -10px;
      position: absolute;
    }

    &__icon {
      display: none !important;
    }
    &__buttons {
      a:first-child {
        display: none !important;
      }
    }
    
  }
}

.news__all-admin-news {
  .messages {
    position: initial !important;
    overflow: initial !important;
  }
  .message {
    width: 97% !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin-left: 0 !important;
    border-bottom: 1px solid #ccc !important;
    border-radius: 0 !important;
    padding-bottom: 20px !important;

    &:last-child {
      border-bottom: none !important;
    }

    &.note {
      display: none;
    }

    &__close {
      display: none !important;
    }

    &__time-author {
      font-weight: bold !important;
    }
    
    &__icon {
      display: none !important;
    }
    
    &__reopen {
      display: none !important;
    }

    &__buttons {
      a:first-child {
        display: none !important;
      }
    }
    &__edit {
      display: block !important;
    }

    &__delete {
      display: block !important;
    }
  }
}

::ng-deep .cdk-overlay-container {
  z-index: 9999 !important;
}

//overlay full overwrite

.overlay__full {
  .info-table__wrapper {
    bottom: 15px !important;
  }
  .invoice {
    bottom: 15px !important;
  }
  .related__wrapper {
    &.first {
      bottom: calc(50% - -15px) !important;
    }
    &.last {
      bottom: 15px !important;
      top: calc(50% - 0px) !important;

      .related__popup {
        top: 0 !important;
      }
    }
  }
  .messages {
    height: calc(100% + 20px) !important;
  }
  .create-note {
    bottom: 0 !important;
    textarea {
      height: calc(100vh - 400px) !important;
    }
  }
}

// year picker style fix
.year-picker-select {
  .mat-option {
    height: 1.5em !important;
  }
  .mat-option-text {
    font-size: 20px !important;
  }
}

// Chosen filters fix for analyses page
.analyze-wrapper .chosen-filters {
  right: 420px !important;
  left: 29px !important;
  top: 3px !important;
}

// Remove column picker btn from overlay

.overlay {
  .column-picker-btn {
    display: none !important;
  }
  .total-rows {
    display: none !important;
  }
  .action-selector {
    width: calc(100% - 105px) !important;
  }
}

// overwrite datepicker

.filter-month-picker {
  .mat-calendar {
    height: auto !important;
  }

  .mat-calendar-table-header {
    display: none !important;
  }

  .mat-calendar-body-label {
    display: none !important;
  }
}

// Fix correct width for text, on different layers when filterbar is expanded extra

.filter-bar__expanded {
  .favorites__text {
    width: 95% !important;
  }

  .mat-nested-tree-node li {
    ul {
      .mat-nested-tree-node li {
        div {
          > span {
            width: 380px !important;
          }
        }
        ul {
          .mat-nested-tree-node li {
            span {
              // width: 355px !important;
            }
          }
        }
      }
    }
  }

  .mat-nested-tree-node > li > ul > .mat-nested-tree-node > li > ul > .mat-tree-node > li > span {
    width: 355px !important;
  }

  .mat-nested-tree-node > li > ul > .mat-tree-node > li > span {
    width: 355px !important;
  }

  .mat-nested-tree-node
    > li
    > ul
    > .mat-nested-tree-node
    > li
    > ul
    > .mat-nested-tree-node
    > li
    > ul
    > .mat-nested-tree-node
    > li
    > ul
    > .mat-tree-node
    > li
    > span {
    width: 385px !important;
  }

  .mat-nested-tree-node
    > li
    > ul
    > .mat-nested-tree-node
    > li
    > ul
    > .mat-nested-tree-node
    > li
    > ul
    > .mat-tree-node
    > li
    > span {
    width: 415px !important;
  }
}

// HAS FILTER
.has-filter {
  input {
    background-color: $color-ui !important;
    color: white !important;
  }
  .table__filter-btn {
    background-color: $color-ui !important;
    color: white !important;
  }
  .mat-icon-button {
    color: white !important;
  }
}

// Fix leader-dashboard when sidebar open
.content.fixed {
  .leader-dashboard {
    margin-top: 0;
  }
}

// Heirarchy in user settings

.user-setting-dialog {
  .mat-tree {
    margin-left: -10px !important;
  }

  .mat-nested-tree-node > li > ul > .mat-nested-tree-node > li > ul > .mat-tree-node > li > span {
    width: 570px !important;
  }

  .mat-nested-tree-node > li > .mat-tree-node > span {
    width: 570px !important;
  }

  .mat-icon-button {
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-left: 10px;
    margin-right: 10px;

    fa-icon {
      top: -1px !important;
    }
  }
}
