@use '@angular/material' as mat;
@import "@angular/material/theming";

$custom-typography: mat.define-typography-config(
  $font-family: "Roboto",
);

* {
  font-family: "Roboto", sans-serif;
}

@font-face {
  font-family: "raleway";
  src: url("/assets/fonts/Montserrat.ttf");
}
