$color-bg: #fff;
$color-row: #f3f4f7;
$color-border: #e3e6eb;
$color-tekst01: #9ca3b0;
$color-tekst02: #5a667b;

// Signal-H
$color-signal-h-lighter: #fecbb2;
$color-signal-h-light: #f7977c;
$color-signal-h: #d35c48;
$color-signal-h-dark: #af2113;
$color-signal-h-darker: #910c05;

// Signal-M
$color-signal-m-lighter: #f3e977;
$color-signal-m-light: #e2cd4d;
$color-signal-m: #e3bc34;
$color-signal-m-dark: #c98a0d;
$color-signal-m-darker: #b26f04;

// Signal-L
$color-signal-l-lighter: #9eecbf;
$color-signal-l-light: #74cb93;
$color-signal-l: #4ea169;
$color-signal-l-dark: #17662b;
$color-signal-l-darker: #003f00;

// Graph
$color-graph-lighter: #97d3e4;
$color-graph-light: #71aec3;
$color-graph: #44758b;
$color-graph-dark: #12364c;
$color-graph-darker: #051929;

// UI
$color-ui: #607d8b;
$color-ui-alt: #648b9e;
